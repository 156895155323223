
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of users</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-md-6 col-lg-4 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li>
                <button @click.prevent="toggleFilters()" class="dropdown-item" type="button">
                  Toggle Filters
                </button>
              </li>
              <li>
                <button @click.prevent="exportUsers()" class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group w-auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            
            <router-link class="btn btn-primary" to="/users/create">
            <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Add User </span></router-link>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="">
        <common-portlet headertitle="Filters"
         :visible="enableFilters" :open="true">
          <div class="loading-viewport">
            <div class="card-body p-lg-3">
              <user-filter-form 
              @startSearch="setFilters($event)"
              @resetFilters="initializeAll()" />
            </div>
          </div>
        </common-portlet>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(image)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <img class="avatar rounded-circle" :src="absoluteUrl(data.item.image)" />
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.first_name }} {{ data.item.last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(mobile)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.mobile }}</span>
                </div>
              </template>
              <template v-slot:cell(email)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.email }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span v-html="statusBadge(data.item.status)" class=""></span>
                </div>
              </template>
              <template v-slot:cell(is_verified)="data">
                <span class="text-capitalize badge" :class="data.item.is_verified ? 'bg-primary' : 'bg-danger'"> 
                    {{ data.item.is_verified ? '✓' :'X' }} </span>
              </template>
              <template v-slot:cell(action)="data">
                <div class="dropdown dropstart no-arrow">
                  <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click.prevent="navigateTo(data.item.id)" class="dropdown-item"
                        type="button"> View </button>
                    </li>
                    <li v-if="data.item.id != activeUser.id">
                      <button @click.prevent="deleteItem(data.item.id)" class="dropdown-item"
                        type="button"> Delete </button>
                    </li>
                  </ul>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';
import CommonPortlet from '@/components/portlets/CommonPortlet.vue';
import UserFilterForm from '@/components/forms/UserFilterForm.vue';

export default {
  name: "user-list",
  components:{
    IsLoading,
    CommonPortlet,
    UserFilterForm
  },
  data() {
    return {
      isLoading: true,
      enableFilters: false,
      queryFilters: null,
      searchQuery: "",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
            key: "image",
            label: "Image",
        },
        {
            key: "name",
            label: "Name",
        },
        {
            key: "mobile",
            label: "Phone",
        },
        {
            key: "email",
            label: "Email",
        },
        {
            key: "status",
            label: "Status",
        },
        {
          key: "is_verified",
          label: "Verified",
        },
        "action"
      ],
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    pageData(){
      return this.$store.state.userList.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    toggleFilters(){
      this.enableFilters = !this.enableFilters; 
      this.initializeAll()
    },
    setFilters(data){
      this.queryFilters = data
      this.fetchItems()
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
      this.isLoading = true
      this.$store.dispatch("userList/fetchUsers", payload)
      .then(response => this.isLoading = false)
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You want to delete a user",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/users/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('userList/DELETE_USER', itemId)
            }
          })
        }
      });
    },
    exportUsers() {
      const payload = {
        url: `/users/export-excel?${new URLSearchParams(this.queryFilters || {}).toString()}`,
        filename: 'users_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate clients');
          }
      })
    },
    initializeAll(){
      this.queryFilters = null;
    }
  },
  created() {
    this.fetchItems()
  }
}

</script>

