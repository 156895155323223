
<template>
<form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row gx-lg-4 g-3">
        <div class="col-sm-6 col-md-4">
            <label for="country_id" class="form-label">Country</label>
            <select @change="countryChange()" v-model="data.country_id" 
                name="country_id" id="country_id" class="form-select">
                <option disabled value="" >--choose country--</option>
                <option v-for="(country, coIndex) in countryList" :key="coIndex" 
                :value="country.id">{{country.name}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-4">
            <label for="state_id" class="form-label">State</label>
            <select @change="stateChange()" v-model="data.state_id" 
                name="state_id" id="state_id" class="form-select">
                <option disabled value="" >--choose state--</option>
                <option v-for="(state, sIndex) in stateOptions" :key="sIndex" 
                :value="state.id">{{state.name}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-4">
            <label> Local Government </label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="name" label="name"
            :options="lgaOptions" 
            v-model="data.local_government_id" placeholder="--select LGA --"></multiselect>
        </div> 
        <div class="col-sm-6 col-md-4">
            <label for="gender" class="form-label">Gender</label>
            <select v-model="data.gender" name="gender"
             id="gender" class="form-select" >
            <option value="" > All genders </option>
            <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                :value="gender.value">{{gender.label}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-4">
            <label class="form-label">Role</label>
            <select class="form-select" v-model="data.role_id" 
                name="input-role" placeholder="--select role--">
                <option value=""> All roles </option>
                <option :value="role.id" v-for="(role, index) in roleOptions" 
                :key="index">{{$filters.capitalize(role.name)}}</option>
            </select>
        </div>
        <div class="col-sm-6 col-md-4">
            <label class="form-label">Status</label>
            <select class="form-select" v-model="data.status"
                name="input-role" placeholder="--select status--">
                <option  value="" key="-1"> All status </option>
                <option :value="status.value" v-for="(status, index) in statusOptions" 
                :key="index">{{status.label}}</option>
            </select>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>
import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue'

export default {
    components: {
        IsLoading,
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            mirrorData: null,
            data: {
                country_id: "",
                state_id: "",
                local_government_id: "",
                role_id: "",
                gender: "",
                status: "",
            },
            statusOptions: [
                {label: "Active", value:'active'},
                {label: "Deactivated", value:'deactivated'},
                {label: "Blocked", value:'blocked'}
            ],
            genderOptions: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
                { label: 'Others', value: 'others' }
            ],
            stateOptions:[],
            lgaOptions:[],
            
        }
    },
    computed:{
        roleOptions() {
            return this.$store.state.userList.roles
        },
        countryList(){
            return this.$store.state.countries
        }
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        countryChange(initialize = true){
            if(initialize){
                this.data.state_id = this.data.local_government_id = "";
                this.stateOptions = this.lgaOptions = [];
            }
            if(!this.data.country_id){
                return
            }
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/states?country_id=${this.data.country_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.stateOptions = response.data.data
                }
            })
        },
        stateChange(initialize = true){
            if(initialize){
                this.data.local_government_id = "";
                this.lgaOptions = [];
            }
            if(!this.data.country_id || !this.data.state_id){
                return
            }
            this.isLoading = true
            this.$http.get(`${this.absoluteUrl()}/api/local-governments?country_id=${this.data.country_id}&state_id=${this.data.state_id}`)
                .then((response) => {
                this.isLoading = false
                if(response.data.success){
                    this.lgaOptions = response.data.data
                }
            })
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
        getOptionData(){
            this.isLoading = true
            this.$store.dispatch('fetchCountries')
            this.$store.dispatch("userList/fetchRoles")
            .then(response => this.isLoading = false )
        },
    },
    created(){
        this.getOptionData()
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
